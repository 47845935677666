
<style scoped>
	.content {
		width: 1320px;
		height: 700px;
		margin: 0 auto;
		padding: 0 10px;
		position: relative;
	}
	.content-left {
		width: 660px;
		height: 100%;
		position: absolute;
	}
	.con-div {
		margin-top: 190px;
		width: 64px;
		height: 8px;
		background: #75B9F6;
	}
	.con-div2 {
		width: 180px;
		height: 42px;
		line-height: 42px;
		background-color: #75b9f6;
		font-size: 21px;
		text-align: center;
		color: #fff;
	}
	.content-left > p {
		font-weight: bold;
	}
	.con-text {
		font-size: 31px;
		color: #222;
		font-weight: bold;
		margin: 66px 0 57px;
	}
	.con-text2 {
		font-size: 15px;
		color: #666;
		margin-bottom: 35px;
	}
	.content > img {
		width: 453px;
		height: 412px;
		position: absolute;
		top: 192px;
		right: 0;
	}
	/*  */
	.content2 {
		width: 1320px;
		text-align: center;
		padding: 0 150px;
	}
	.con-div {
		width: 65px;
		height: 8px;
		background: #75B9F6;
		margin-bottom: 115px;
	}
	.con-p {
		font-size: 31px;
		color: #222;
		font-weight: bold;
	}
	.con-p2 {
		font-size: 16px;
		color: #666;
		margin-top: 57px;
		font-weight: bold;
		line-height: 25px;
	}
	/*  */
	.content3 {
		width: 1320px;
		padding: 0 20px;
		margin: 0 auto;
	}
	.content3 > div:last-child {
		margin-right: 0;
	}
	.con3-item {
		width: calc((100% - 480px) / 5);
		margin-right: 120px;
		flex-direction: column;
		height: 210px;
		color: #222;
	}
	.con3-p {
		margin-top: 59px;
		font-size: 20px;
	}
	.con3-active {
		color: #75B9F6 !important;
		border-bottom: 4px solid #75B9F6;
	}
	/*  */
	.content4 {
		height: 380px;
		min-width: 1320px;
		width: 100%;
		background: #F7F8FA;
	}
	.con4 {
		width: 1320px;
		height: 100%;
		margin: 0 auto;
		padding: 0 75px;
	}
	.con4-item {
		height: 240px;
		width: 310px;
		padding: 25px 50px;
	}
	.con4-item > p:nth-child(1) {
		color: #75b9f6;
		font-size: 39px;
		text-align: center;
		margin-bottom: 32px;
		font-weight: bold;
	}
	.con4-item > p:nth-child(2) {
		font-size: 12px;
		color: #666;
		line-height: 16px;
	}
	.con4-active {
		background: #fff;
		border-radius: 10px;
		box-shadow: 0 0 20px #F2F3F5;
	}
</style>

<template>
	<div>
		<div class="content">
			<div class="content-left">
				<div class="con-div"></div>
				<p class="con-text">抗感解毒颗粒</p>
				<p class="con-text2">
					适应症：清热解毒。本品用于风热感冒。	
				</p>
				<p class="con-text2">
					商品名称：抗感解毒颗粒<br>
					通用名称：抗感解毒颗粒<br>
					批准文号：国药准字Z20083299<br>
					规格：10g*9袋<br>
					包装：10g*9袋/盒<br>
					用法用量：开水冲服,一次10克,一日3次,重症一日4～6次,小儿酌减或遵医嘱。<br>
					分类：中成药<br>
					类型：OTC乙类<br>
					医保：非医保<br>
					剂型：颗粒剂<br>
					性状：本品为棕黄色的颗粒，味甜，微苦。<br>
					外用药：否<br>
					有效期：24个月
				</p>
			
			</div>
			<img src="../../../assets/images/plan-010.jpeg" alt="">
		</div>

		<div class="content">
			<div class="content-left">
				<div class="con-div"></div>
				<p class="con-text">力弗罗红霉素颗粒</p>
				<p class="con-text2">
					适应症：1.本品适应于敏感菌株引起的下列感染	
				</p>
				<p class="con-text2">
					商品名称：罗红霉素颗粒<br>
					通用名称：罗红霉素颗粒<br>
					主要成份：本品主要成份:罗红霉素。<br>
					禁忌：对本品﹑红霉素或其他大环内酯类药物过敏者禁用。<br>
					孕妇及哺乳期妇女用药：孕妇及哺乳期妇女慎用。<br>
					老人用药：如老年人的药动学无明显改变，不需调整剂量。<br>
					药物相互作用：未进行该项研究且暂无可靠文献参考。<br>
					药物过量：未进行该项研究且暂无可靠文献参考。<br>
					药物毒理：新一代大环内酯类抗生素，主要作用于革兰阳性菌、厌氧菌、衣原体和支原体等。其体外抗菌作用与红霉素相类似，体内抗菌作用比红霉素强1～4倍。<br>
					贮藏：密封，在干燥处保存。
									
				</p>
			
			</div>
			<img src="../../../assets/images/plan-011.jpeg" alt="">
		</div>

		
		<Bottom></Bottom>
	</div>
</template>

<script>
	import Bottom from "../../../../components/bottom.vue";
	export default {
		name: "prouctPlan",
		data() {
			return {
				content3List: [
					{title: "增强企业品牌", img: require("../../../assets/images/plan-2.png"), img2: require("../../../assets/images/plan-2-2.png")},
					{title: "减少企业客户流失", img: require("../../../assets/images/plan-3.png"), img2: require("../../../assets/images/plan-3-2.png")},
					{title: "加强客户管理效率", img: require("../../../assets/images/plan-4.png"), img2: require("../../../assets/images/plan-4-2.png")},
					{title: "实现企业业绩增长", img: require("../../../assets/images/plan-5.png"), img2: require("../../../assets/images/plan-5-2.png")},
					{title: "节省企业运营成本", img: require("../../../assets/images/plan-6.png"), img2: require("../../../assets/images/plan-6-2.png")},
				],
				con3Index: 0,
				content4List: [
					[
						{ content: "增大品牌在市场中的占有比例，让企业获得长期的生活动力。" }
					],
					[
						{ content: "有效提升客户对产品以及服务满意度。" },
						{ content: "提供更便捷周到的售前、售后服务体系。" },
						{ content: "更快速、更全面的拓宽市场渠道，了解客户的需求，及时予以反馈及解决。" }
					],
					[
						{ content: "制定合理的流程设计通过流程管理，加强制度建设，提升企业执行力，加强内部控制，建立和完善监督与评价体系。" },
						{ content: "时间管理和目标管理，科学规划最少的时间办最重要的事情，确定目标和制定计划。" },
						{ content: "提高决策能力和完善评估系统，有效避免决策失误、避免执行过程中造成的重复工作，积累先进的工作经验来提高管理效率" }
					],
					[
						{ content: "客户是企业盈利的来源，专业严谨的产品策划能让企业拥有更多的利润，获得更大的发展空间。" }
					],
					[
						{ content: "有效更新运营成本的观念提高资源配置，降低成本费用。" },
						{ content: "注重与产品相关的外部供应商等的协调配合，促进各有关方面参与企业的成本管理。" },
						{ content: "更好选择适合的合作伙伴来提高资源配置效率，以尽可能少的成本付出，创造尽可能多的使用价值，为企业获取更多的经济效益。" }
					],
				],
				con4Index: 0,
			}
		},
		components: {Bottom}
	}
</script>


